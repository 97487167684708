import {
  CharacterEmbedMetadata,
  CharacterMetadata,
  CharacterSentimentEnum,
  SurveyMetadata,
  SurveySubmitMetadata,
} from './types'

export const DEFAULT_CHARACTER_METADATA: CharacterMetadata = {
  characterDescription: '',
  instructions: '',
  sentiment: CharacterSentimentEnum.neutral,
  isNsfw: false,
}

export const DEFAULT_CHARACTER_EMBED_METADATA: CharacterEmbedMetadata = {
  subTitle: '',
  highlight: '',
}

export const DEFAULT_SURVEY_METADATA: SurveyMetadata = {
  subTitle: '',
  highlight: '',
  template: [],
}

export const DEFAULT_SURVEY_SUBMIT_METADATA: SurveySubmitMetadata = {
  answers: [],
}

export const getParsedCharacterMetadata = (characterMetadata: string) => {
  try {
    const metaData = JSON.parse(characterMetadata) as CharacterMetadata
    if (typeof metaData.characterDescription !== 'undefined') {
      return metaData
    }
  } catch {}

  return DEFAULT_CHARACTER_METADATA
}

export const getParsedCharacterEmbedMetadata = (characterEmbedMetadata: string) => {
  try {
    const metaData = JSON.parse(characterEmbedMetadata) as CharacterEmbedMetadata
    if (typeof metaData.subTitle !== 'undefined') {
      return metaData
    }
  } catch {}

  return DEFAULT_CHARACTER_EMBED_METADATA
}

export const getParsedSurveyMetadata = (characterEmbedMetadata: string) => {
  try {
    const metaData = JSON.parse(characterEmbedMetadata) as SurveyMetadata
    if (typeof metaData.subTitle !== 'undefined') {
      return metaData
    }
  } catch {}

  return DEFAULT_SURVEY_METADATA
}

export const getParsedSurveySubmitMetadata = (surveySubmitMetadata: string) => {
  try {
    const metaData = JSON.parse(surveySubmitMetadata) as SurveySubmitMetadata
    if (typeof metaData.answers !== 'undefined') {
      return metaData
    }
  } catch {}

  return DEFAULT_SURVEY_SUBMIT_METADATA
}

export const CHARACTER_SENTIMENTS = [
  {
    emoji: '🙂',
    label: 'Neutral',
    value: CharacterSentimentEnum.neutral,
    description:
      'Maintains a neutral tone and provides straightforward responses without any emotional undertones',
  },
  {
    emoji: '🤪',
    label: 'Funny',
    value: CharacterSentimentEnum.funny,
    description: 'Uses humor to lighten up the mood and make the user laugh',
  },
  {
    emoji: '😍',
    label: 'Romantic',
    value: CharacterSentimentEnum.romantic,
    description:
      'Expresses romantic feelings towards the user or provides advice on how to enhance romantic relationships',
  },
  {
    emoji: '🤬',
    label: 'Mad',
    value: CharacterSentimentEnum.mad,
    description:
      'Displays anger or frustration towards the user or a situation, and may provide harsh feedback or criticism.',
  },
].map((item) => ({ ...item, label: `${item.emoji} ${item.label}` }))
