export interface CharacterMetadata {
  systemMessageOverride?: string
  characterDescription: string
  instructions: string
  sentiment: CharacterSentimentEnum
  isNsfw: boolean
}

export interface CharacterEmbedMetadata {
  subTitle: string
  highlight: string
}

export interface SurveyMetadata {
  subTitle: string
  highlight: string
  template: Array<{
    id: string
    question: string
    questionContext: string
    answerType: string
  }>
}

export interface SurveySubmitMetadata {
  answers: Array<{
    id: string
    answer: string
    answerContext: string
  }>
}

export enum CharacterSentimentEnum {
  neutral = 'neutral',
  mad = 'mad',
  funny = 'funny',
  romantic = 'romantic',
}
