import * as React from 'react'

import { DocumentTypeEnum, useCreateSourceDocumentMutation } from '../../../generated/graphql'
import { DragAndDropUploader, DragAndDropUploaderProps } from './SimpleDragAndDropUploader'

interface Props
  extends Omit<DragAndDropUploaderProps, 'isLoading' | 'onUploadFinished' | 'onFileUploaded'> {
  documentType: DocumentTypeEnum
  onFileUploaded: (key: string) => void
  onDocumentUploaded: (key: string, sourceDocumentId: string | null) => void
}
export function SourceDocumentDragAndDropUploader({
  accept,
  documentType,
  isPublic = false,
  onDocumentUploaded,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [createSourceDocumentMutation] = useCreateSourceDocumentMutation()

  const handleUploadFinished = React.useCallback(
    async (key: string) => {
      const { data } = await createSourceDocumentMutation({
        variables: {
          inputData: {
            s3FileKey: key,
            documentType,
          },
        },
      })
      setIsLoading(false)
      onDocumentUploaded(key, data?.createSourceDocument?.id ?? null)
    },
    [documentType, onDocumentUploaded, createSourceDocumentMutation]
  )

  return (
    <DragAndDropUploader
      accept={accept}
      isLoading={isLoading}
      isPublic={isPublic}
      onUploadStart={() => setIsLoading(true)}
      onUploadFinished={handleUploadFinished}
    />
  )
}
