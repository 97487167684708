import { FileWithPath } from '@mantine/dropzone'
import * as React from 'react'

import { useUploadFile } from '../../utils/useUploadFile'
import { DropZone, Props as DropZoneProps } from './DropZone'

export interface DragAndDropUploaderProps
  extends Pick<DropZoneProps, 'accept' | 'isLoading' | 'isDisabled'> {
  onUploadFinished: (key: string) => void
  onUploadStart: () => void
  isPublic?: boolean
}

export function DragAndDropUploader({
  accept,
  isPublic = false,
  isDisabled,
  isLoading,
  onUploadFinished,
  onUploadStart,
}: DragAndDropUploaderProps) {
  const uploadFile = useUploadFile()

  const uploadFiles = React.useCallback(
    ([file]: FileWithPath[]) => {
      void uploadFile({
        file,
        isPublic,
        onError: () => {},
        onUploadFinished,
        onUploadStart: onUploadStart,
        setPreviewMediaURL: () => {},
        setProgress: () => {},
      })
    },
    [uploadFile, isPublic, onUploadFinished, onUploadStart]
  )

  return (
    <DropZone accept={accept} isLoading={isLoading} isDisabled={isDisabled} onDrop={uploadFiles} />
  )
}

interface Props extends Omit<DragAndDropUploaderProps, 'isLoading' | 'onUploadFinished'> {
  onFileUploaded: (key: string) => void | Promise<void>
}
export function SimpleDragAndDropUploader({
  accept,
  isDisabled,
  isPublic = false,
  onFileUploaded,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <DragAndDropUploader
      accept={accept}
      isPublic={isPublic}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onUploadStart={() => setIsLoading(true)}
      onUploadFinished={async (key) => {
        await onFileUploaded(key)
        setIsLoading(false)
      }}
    />
  )
}
