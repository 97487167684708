import {
  Avatar,
  Button,
  Flex,
  Group,
  Indicator,
  Input,
  LoadingOverlay,
  Modal,
  Pagination,
  SimpleGrid,
  Stack,
  Tabs,
  UnstyledButton,
  getDefaultZIndex,
} from '@mantine/core'
import { MIME_TYPES } from '@mantine/dropzone'
import { useDisclosure } from '@mantine/hooks'
import { IconCheck, IconPhoto, IconUpload } from '@tabler/icons-react'
import React from 'react'

import {
  OrderByDirectionEnum,
  UserRoleEnum,
  useCharacterImagesQuery,
  useCreateCharacterImageMutation,
} from '../../../generated/graphql'
import { SimpleDragAndDropUploader } from '../../components/SimpleDragAndDropUploader'
import { DataLimit } from '../../constants'
import { useAuth } from '../../utils/auth'
import { RefetchQueriesEnum } from '../../utils/constants'
import { getPagination } from '../../utils/helpers-client'

interface Props {
  isPublicUpload?: boolean
  selectedFileKey?: string | null
  onFileKeySelect: (key: string) => void
}

export function PublicImages({ selectedFileKey, onFileKeySelect, isPublicUpload }: Props) {
  const {
    data: { User },
  } = useAuth()
  const [activeTab, setActiveTab] = React.useState<string | null>('gallery')
  const [page, setPage] = React.useState(1)
  const characterImagesQuery = useCharacterImagesQuery({
    variables: {
      filterData: {
        userIds: [User.id!, null],
      },
      pagination: getPagination({
        dataLimit: 'characterImages',
        page: String(page),
        orderBy: 'createdAt',
        orderByDirection: OrderByDirectionEnum.Desc,
      }),
    },
  })
  const [createCharacterImageMutation] = useCreateCharacterImageMutation()
  const [opened, { open, close }] = useDisclosure(false)

  React.useEffect(() => {
    const fileKey = characterImagesQuery.data?.characterImages.characterImages[0]?.key
    if (!selectedFileKey && fileKey) {
      onFileKeySelect(fileKey)
    }
  }, [selectedFileKey, onFileKeySelect, characterImagesQuery])

  return (
    <>
      <Modal opened={opened} onClose={close} title="Avatar">
        <Tabs value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="gallery" icon={<IconPhoto size="0.8rem" />}>
              Gallery
            </Tabs.Tab>
            <Tabs.Tab value="upload" icon={<IconUpload size="0.8rem" />}>
              Upload
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="gallery" pt="xs">
            <Stack spacing={0}>
              <LoadingOverlay
                visible={characterImagesQuery.loading}
                overlayBlur={2}
                zIndex={getDefaultZIndex('max')}
              />
              <SimpleGrid cols={6}>
                {characterImagesQuery.data?.characterImages.characterImages.map(
                  ({ key, thumbnailSignedUrl }) => (
                    <Indicator
                      key={key}
                      label={<IconCheck size="0.75rem" />}
                      color="green"
                      size={22}
                      offset={4}
                      disabled={key !== selectedFileKey}
                    >
                      <Avatar
                        radius="md"
                        size="lg"
                        src={thumbnailSignedUrl}
                        onClick={() => {
                          onFileKeySelect(key)
                        }}
                      />
                    </Indicator>
                  )
                )}
              </SimpleGrid>

              {(characterImagesQuery.data?.characterImages.pagination.total ?? 0) >
                DataLimit.characterImages && (
                <Flex justify="center" mt="md">
                  <Pagination
                    value={page}
                    onChange={setPage}
                    total={Math.ceil(
                      (characterImagesQuery.data?.characterImages.pagination.total ?? 0) /
                        DataLimit.conversations
                    )}
                  />
                </Flex>
              )}
            </Stack>
          </Tabs.Panel>

          <Tabs.Panel value="upload" pt="xs">
            <SimpleDragAndDropUploader
              isPublic={isPublicUpload ?? User.role === UserRoleEnum.SuperAdmin}
              accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.webp]}
              onUploadStart={() => {}}
              onFileUploaded={async (key) => {
                setActiveTab('gallery')
                await createCharacterImageMutation({
                  variables: {
                    inputData: {
                      userId: User.id!,
                      isPublic: User.role === UserRoleEnum.SuperAdmin,
                      s3FileKey: key,
                    },
                  },
                  refetchQueries: [RefetchQueriesEnum.CharacterImages],
                  awaitRefetchQueries: true,
                })
                onFileKeySelect(key)
              }}
            />
          </Tabs.Panel>
        </Tabs>
      </Modal>

      <Stack spacing={0}>
        <Group position="apart">
          <Input.Label>Avatar</Input.Label>
          <Button
            size="sm"
            color="gray"
            variant="light"
            radius="md"
            compact
            onClick={() => {
              setActiveTab('gallery')
              open()
            }}
          >
            See all
          </Button>
        </Group>
        <Group>
          <UnstyledButton>
            <Avatar
              radius="md"
              size="lg"
              onClick={() => {
                setActiveTab('upload')
                open()
              }}
            >
              <IconUpload />
            </Avatar>
          </UnstyledButton>
          {characterImagesQuery.data?.characterImages.characterImages
            .slice(0, 4)
            .map(({ key, thumbnailSignedUrl }) => (
              <Indicator
                key={key}
                label={<IconCheck size="0.75rem" />}
                color="green"
                size={22}
                offset={4}
                disabled={key !== selectedFileKey}
              >
                <Avatar
                  radius="md"
                  size="lg"
                  src={thumbnailSignedUrl}
                  onClick={() => {
                    onFileKeySelect(key)
                  }}
                />
              </Indicator>
            ))}
        </Group>
      </Stack>
    </>
  )
}
